<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link>
                </p>
                </v-col>
            </v-row> -->
        <v-row justify="center" class="py-5 mt-2">
            <v-col cols="12" sm="10" md="8" lg="6" xl="6" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">Nameserver Lookup</h1>
                <v-form @submit.prevent="search" @keyup.enter.native="search">
                    <v-text-field v-model="searchQuery" ref="searchInput" dense solo color="purple darken-2" label="Domain" hint="Enter a domain name to look up nameserver information">
                        <template #prepend-inner>
                            <font-awesome-icon :icon="['fas', 'search']" fixed-width color="purple darken-2"/> <!-- style="font-size: 20px;" -->
                        </template>
                    </v-text-field>
                </v-form>
                <!-- <h1 class="text-h6 font-weight-light text-center">{{ domain.name }}</h1> -->
                <!-- <p class="text-caption" color="grey--text text-lighten-2">Whois</p> -->
                <v-card class="mt-2" v-if="Array.isArray(nameserverList)">
                    <v-toolbar :color="primaryColor" dense flat dark>
                        <v-toolbar-title>
                            Authoritative Nameservers
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <!-- <p class="text-overline mb-0">Registrar</p> -->
                <template v-if="nameserverStatus === 'ready'">
                <p class="mb-0 pb-0">
                    <font-awesome-icon :icon="['fas', 'check']" class="green--text"/>
                    DNS hosted by LibertyDNS.
                </p>
                </template>
                <template v-if="nameserverStatus === 'unassigned'">
                <p class="mb-0 pb-0">
                    <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="amber--text"/>
                    This domain is not assigned to your account.
                    <router-link :to="{ name: 'account-create-domain', params: { accountId: this.$route.params.accountId }, query: { domain: this.searchQuery } }"><span class="black--text text--lighten-2">Add this domain</span></router-link>
                </p>
                </template>
                <template v-if="nameserverStatus === 'conflict'">
                <p class="mb-0 pb-0">
                    <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="amber--text"/>
                    This domain is not assigned to your account.
                </p>
                <p class="mb-0 pb-0 text-caption">
                    <!-- TODO: link to start dispute -->
                    <!-- <router-link :to="{ name: '??? dispute ???', params: { accountId: this.$route.params.accountId }, query: { domain: this.searchQuery } }"><span class="black--text text--lighten-2">Start dispute</span></router-link> -->
                </p>
                </template>
                <template v-if="nameserverStatus === 'other'">
                <p class="mb-0 pb-0">
                    <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="amber--text"/>
                    DNS hosted elsewhere.
                </p>
                <p class="mb-0 pb-0 text-caption">
                    You can edit DNS records, but they will only be visible to queries directed at our servers specifically.
                </p>
                </template>
                <template v-if="nameserverStatus === 'pending'">
                <p class="mb-0 pb-0">
                    <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="amber--text"/>
                    DNS changes pending.
                </p>
                <p class="mb-0 pb-0 text-caption">
                    Looks like nameservers are being changed, check again later for updates. You can edit DNS records, but results may be unreliable until the change is complete.
                </p>
                </template>
                <p class="mb-0 pb-0" v-if="domain && nameserverList.length === 0 && nameserverStatus !== 'error'">
                    No nameservers set for this domain.
                </p>
                <p class="mb-0 pb-0" v-if="nameserverList.length > 0">
                    Nameservers:
                    <v-chip label small v-for="(item, idx) in nameserverList" :key="idx" class="ml-1">
                        {{ item }}
                    </v-chip>
                </p>
                <template v-if="registrarDnsNameservers.length > 0 && nameserverStatus === 'error'">
                <p class="mb-0 pb-0">
                    <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="amber--text"/>
                    DNS configuration error.
                </p>
                <p class="mb-0 pb-0 text-caption">
                    The nameservers configured at the registrar are not currently serving DNS information for this domain.
                </p>
                <p class="mb-0 pb-0">
                    Nameservers:
                    <v-chip label small v-for="(item, idx) in registrarDnsNameservers" :key="idx" class="ml-1">
                        {{ item }}
                    </v-chip>
                </p>
                </template>
                <template v-if="registrarDnsNameservers.length === 0 && nameserverStatus === 'error'">
                <p class="mb-0 pb-0">
                    <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="amber--text"/>
                    Failed to look up nameservers.
                </p>
                <p class="mb-0 pb-0 text-caption">
                    Try again later, or check the settings at the domain registrar.
                </p>
                </template>
                <template v-if="domain && nameserverStatus !== 'ready'">
                <p class="mb-0 pb-0">
                    <router-link :to="{ name: 'account-edit-domain-nameservers', params: { accountId: this.$route.params.accountId, domain: this.searchQuery } }">Change nameservers</router-link>
                </p>
                </template>
                    </v-card-text>
                </v-card>
                <v-alert v-if="error" type="error" class="mt-6">
                    We were not able to look up the information at this time.
                </v-alert>
            </v-col>
        </v-row>

        </v-col>
    </v-row>
</template>

<style>
/* regular input height is 56px; dense input height is 40px */
/* font awesome icon width is 16px, while append/prepend-inner width is 20px */
.v-input .v-input__prepend-inner {
    margin-left: 2px !important; /* (20px placeholder width - 16px icon width) / 2 */
    padding-left: 2px !important;
    margin-top: 12px !important; /* (40px input height - 16px icon height) / 2 */
    margin-bottom: 12px !important;
    padding: 0px;
}
</style>

<script>
import { mapState, mapGetters } from 'vuex';
// import RealmList from '@/components/domain-dashboard/RealmList.vue';

export default {
    components: {
        // RealmList,
    },
    data: () => ({
        account: null,
        domain: null,
        error: null,
        errorTimeout: null,
        searchQuery: null,
        searchTimestamp: null,
        notFound: false,
        notFoundTimeout: null,
        nameserverList: null,
        nameserverStatus: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
        }),
        accountName() {
            return this.account?.name ?? '';
        },
        // registrarName() {
        //     return this.whois?.info?.Registrar?.join(' / ') ?? 'Unknown';
        // },
        // registrarURL() {
        //     try {
        //         const list = this.whois?.info?.['Registrar URL'];
        //         if (Array.isArray(list) && list.length > 0) {
        //             const inputURL = new URL(list[0]);
        //             return inputURL.toString();
        //         }
        //         return null;
        //     } catch (err) {
        //         return null;
        //     }
        // },
        registrarDnsNameservers() {
            return this.whois?.info?.['Name Server'] ?? [];
        },
        // TODO: move this getter to vuex so we don't have to redefine it everywere... we'll just use mapGetters
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
    },
    methods: {
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        reset() {
            this.error = false;
            if (this.errorTimeout) {
                clearTimeout(this.errorTimeout);
                this.errorTimeout = null;
            }
            this.notFound = false;
            if (this.notFoundTimeout) {
                clearTimeout(this.notFoundTimeout);
                this.notFoundTimeout = null;
            }
            this.nameserverList = null;
        },
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).self.get();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                // this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadDomain() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadDomain: true });
                const response = await this.$client.account(this.$route.params.accountId).domain.get({ name: this.searchQuery });
                console.log(`domain/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.domain = response;
                } else {
                    // TODO: redirect back to domain list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load domain', err);
                // this.error = true;
            } finally {
                this.$store.commit('loading', { loadDomain: false });
            }
        },
        async search(options = {}) {
            if (Number.isInteger(this.searchTimestamp) && this.searchTimestamp + 500 > Date.now()) {
                return;
            }
            this.searchTimestamp = Date.now();
            try {
                if (this.$route.query.q !== this.searchQuery) {
                    // update URL in address bar
                    this.$router.replace({ name: 'account-view-nameservers', params: { accountId: this.$route.params.accountId }, query: { q: this.searchQuery } });
                }
                this.reset();
                this.$store.commit('loading', { search: true });
                const { refresh = false } = options;
                const response = await this.$client.account(this.$route.params.accountId).domain.checkNameservers({ domain: this.searchQuery, refresh });
                console.log(`checkNameservers response ${JSON.stringify(response)}`);
                if (Array.isArray(response?.list)) {
                    this.nameserverList = response.list;
                } else {
                    this.notFound = true;
                    this.notFoundTimeout = setTimeout(() => { this.notFound = false; }, 15000); // clear message in 15 seconds
                }
                if (typeof response.status === 'string') {
                    this.nameserverStatus = response.status;
                }
            } catch (err) {
                console.error('failed to search', err);
                this.error = true;
                this.errorTimeout = setTimeout(() => { this.error = false; }, 15000); // clear message in 15 seconds
            } finally {
                this.$store.commit('loading', { search: false });
            }
        },
    },
    created() {
        // listen for search events coming from any component (but specifically the navbar search button)
        this.$bus.$on('search', (q) => {
            this.searchQuery = q;
            this.$nextTick(() => {
                setTimeout(() => { this.activate('searchInput'); }, 1);
            });
            if (q) {
                this.search();
            } else {
                this.reset();
            }
        });
    },
    mounted() {
        this.loadAccount();
        this.searchQuery = this.$route.query.q ?? '';
        if (this.searchQuery) {
            this.loadDomain(); // TODO: use this info to compare against published authoritative nameservers... if they're not the same we should warn the user.
            this.search();
        }
        this.$nextTick(() => {
            setTimeout(() => { this.activate('searchInput'); }, 1);
        });
    },
};
</script>
